// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle3 from "../css/fyaKgdpsH";
import * as sharedStyle from "../css/gmLivuJ9i";
import * as sharedStyle1 from "../css/STjGENztc";
import * as sharedStyle2 from "../css/UJmmBKC3t";
const RichTextWithFX = withFX(RichText);

const cycleOrder = ["PtswagtWP", "c51qXPLkp", "F0X_jyIRw"];

const serializationHash = "framer-UTJgq"

const variantClassNames = {c51qXPLkp: "framer-v-1uxzgwh", F0X_jyIRw: "framer-v-dbyya3", PtswagtWP: "framer-v-dpb2fa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const animation = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 20}

const transition2 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const animation1 = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition2, x: 0, y: 20}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {Center: "center", End: "flex-end", Start: "flex-start"}

const humanReadableVariantMap = {Desktop: "PtswagtWP", Phone: "F0X_jyIRw", Tablet: "c51qXPLkp"}

const getProps = ({align, height, id, richText, width, ...props}) => { return {...props, ooGD6ZyqH: humanReadableEnumMap[align] ?? align ?? props.ooGD6ZyqH ?? "flex-end", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "PtswagtWP", yysfn6xN6: richText ?? props.yysfn6xN6 ?? <React.Fragment><motion.p>This is a placeholder.</motion.p><motion.p>This is a placeholder</motion.p></React.Fragment>} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;richText?: string;align?: keyof typeof humanReadableEnumMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, yysfn6xN6, ooGD6ZyqH, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "PtswagtWP", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className, sharedStyle2.className, sharedStyle3.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-dpb2fa", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"PtswagtWP"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--1re7sa5": ooGD6ZyqH, ...style}} {...addPropertyOverrides({c51qXPLkp: {"data-framer-name": "Tablet"}, F0X_jyIRw: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}><RichTextWithFX __framer__animate={{transition: transition2}} __framer__animateOnce __framer__enter={animation} __framer__exit={animation1} __framer__styleAppearEffectEnabled __framer__threshold={0.5} __fromCanvasComponent __perspectiveFX={false} __smartComponentFX __targetOpacity={1} children={yysfn6xN6} className={"framer-m2hchl"} data-framer-name={"Rich Text"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"sYILw0aPg"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "24px", transformPerspective: 1200}} stylesPresetsClassNames={{a: "framer-styles-preset-2e109e", h1: "framer-styles-preset-n4x5jr", h2: "framer-styles-preset-10s4yg", p: "framer-styles-preset-rcobx6"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UTJgq.framer-sgfy5b, .framer-UTJgq .framer-sgfy5b { display: block; }", ".framer-UTJgq.framer-dpb2fa { align-content: var(--1re7sa5); align-items: var(--1re7sa5); display: flex; flex-direction: column; flex-wrap: nowrap; gap: 24px; height: min-content; justify-content: center; overflow: hidden; padding: 56px 64px 56px 64px; position: relative; width: 1440px; }", ".framer-UTJgq .framer-m2hchl { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 48%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-UTJgq.framer-dpb2fa { gap: 0px; } .framer-UTJgq.framer-dpb2fa > * { margin: 0px; margin-bottom: calc(24px / 2); margin-top: calc(24px / 2); } .framer-UTJgq.framer-dpb2fa > :first-child { margin-top: 0px; } .framer-UTJgq.framer-dpb2fa > :last-child { margin-bottom: 0px; } }", ".framer-UTJgq.framer-v-1uxzgwh.framer-dpb2fa { padding: 40px 64px 40px 64px; width: 810px; }", ".framer-UTJgq.framer-v-1uxzgwh .framer-m2hchl { width: 60%; }", ".framer-UTJgq.framer-v-dbyya3.framer-dpb2fa { align-content: flex-start; align-items: flex-start; padding: 20px 24px 20px 24px; width: 390px; }", ".framer-UTJgq.framer-v-dbyya3 .framer-m2hchl { width: 100%; }", ...sharedStyle.css, ...sharedStyle1.css, ...sharedStyle2.css, ...sharedStyle3.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 393.5
 * @framerIntrinsicWidth 1440
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"c51qXPLkp":{"layout":["fixed","auto"]},"F0X_jyIRw":{"layout":["fixed","auto"]}}}
 * @framerVariables {"yysfn6xN6":"richText","ooGD6ZyqH":"align"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCxunlc6yo: React.ComponentType<Props> = withCSS(Component, css, "framer-UTJgq") as typeof Component;
export default FramerCxunlc6yo;

FramerCxunlc6yo.displayName = "projectText";

FramerCxunlc6yo.defaultProps = {height: 393.5, width: 1440};

addPropertyControls(FramerCxunlc6yo, {variant: {options: ["PtswagtWP", "c51qXPLkp", "F0X_jyIRw"], optionTitles: ["Desktop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}, yysfn6xN6: {defaultValue: "<p>This is a placeholder.</p><p>This is a placeholder</p>", title: "Rich text", type: ControlType.RichText}, ooGD6ZyqH: {defaultValue: "flex-end", options: ["flex-start", "center", "flex-end"], optionTitles: ["Start", "Center", "End"], title: "Align", type: ControlType.Enum}} as any)

addFonts(FramerCxunlc6yo, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts), ...getFontsFromSharedStyle(sharedStyle2.fonts), ...getFontsFromSharedStyle(sharedStyle3.fonts)], {supportsExplicitInterCodegen: true})